<template>
  <div class="web-cam-ipv-details-main-wrapper">
    <a-row style="display: unset">
      <a-col>
        <div style="margin-top: 100px">
          <div>
            <div class="web-cam-ipv-details-title">
              Webcam verification (IPV)
            </div>
            <div v-if="mode === modes.IPV_INFO">
              <div class="web-cam-ipv-details-description">
                Write the below code on a piece of paper and hold it in front of
                the camera. Ensure that your face and the code are clearly
                visible.
              </div>
              <div class="web-cam-ipv-details-stepper">Step 4</div>
              <div style="display: flex; justify-content: space-between">
                <div>
                  <span class="web-cam-ipv-code">{{ipvCode}}</span>
                </div>
                <div>
                  <img src="../../../assets/webCamImage.png" />
                </div>
              </div>
              <div class="web-cam-ipv-details-btn-wrapper">
                <a-button type="primary" @click="onClickContinue">Proceed</a-button
                >
              </div>
            </div>
            <div v-if="mode === modes.IPV_CAPTURE">
              <!-- <div class="web-cam-ipv-details-description">
                Write the below code on a piece of paper and hold it in front of
                the camera. Ensure that your face and the code are clearly
                visible.
              </div>
              <div class="web-cam-ipv-details-stepper">Step 4</div>
              <div style="display: flex; justify-content: center">
                <div class="web-cam-ipv-code">{{ipvCode}}</div>
              </div> -->
              <div>
                <div v-if='!capturedImage.file'>
                  <div class="web-cam-ipv-details-stepper">Step 4</div>
                  <camera
                    :resolution='{ width: 200, height: 150 }'
                    ref='camera'
                    :autoplay=true
                  ></camera>
                  <button @click='snapshot' class='web-cam-click-button'>
                    Click
                  </button>
                </div>
                <div v-if='capturedImage.file'>
                  <div class="web-cam-ipv-details-stepper">Step 4</div>
                  <img :src='capturedImage.url'/>
                  <div>
                    <div class='verify-photo-btn-wrapper'>
                      <a-button
                        type='primary'
                        @click='onConfirm'
                        :loading='ipvUploadInProgress'
                        :disabled='ipvUploadInProgress'
                      >
                        Confirm
                      </a-button>
                    </div>
                    <div class='verify-photo-btn-wrapper'>
                      <a-button
                        @click='retakePhoto'
                        :disabled='ipvUploadInProgress'
                      >
                        Retake
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Camera from 'simple-vue-camera';
import { onMounted, ref } from 'vue';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

export default {
  components: { Camera },
  setup() {
    const store = useStore();
    // const router = useRouter();
    const ipvCode = ref('');
    const camera = ref(null);
    const capturedImage = ref({
      file: null,
      url: '',
    });
    const modes = {
      IPV_INFO: 'IPV_INFO',
      IPV_CAPTURE: 'IPV_CAPTURE',
    };
    const mode = ref(modes.IPV_INFO);
    const ipvUploadInProgress = ref(false);

    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          ipvCode.value = response.data.data.prefill.ipv.code;
        })
        .catch(() => {});
    });

    const onClickContinue = () => {
      mode.value = modes.IPV_CAPTURE;
    };

    const snapshot = async () => {
      const blob = await camera.value?.snapshot({ width: 480, height: 320 });
      const file = new File([blob], 'webcam-ipv.png', { type: 'image/png' });
      const url = URL.createObjectURL(blob);
      capturedImage.value = { file, url };
    };

    const retakePhoto = () => {
      capturedImage.value = { file: null, url: '' };
    };

    const uploadIpv = async ({ file }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const data = new FormData();
        data.append('file', file);
        data.append('documentType', 'IPV');
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        await services.uploadDocument(data, config);
      } catch (error) {
        throw error;
      }
    };

    const onConfirm = async () => {
      try {
        ipvUploadInProgress.value = true;
        Operations.showNotification(null, 'Uploading your captured ipv...');
        const { file } = capturedImage.value;
        await uploadIpv({ file });
        Operations.showNotification(null, 'Verifying your captured ipv');
        const { data } = await services.verifyIpv();
        store.dispatch('onboardingStore/updateStage', data.data.stage);
        capturedImage.value = { file: null, url: '' };
      } catch (error) {
        Operations.showNotification(null, 'Failed to upload your captured ipv. Please try again.');
      } finally {
        ipvUploadInProgress.value = false;
      }
    };

    return {
      onClickContinue,
      snapshot,
      retakePhoto,
      onConfirm,
      capturedImage,
      camera,
      ipvCode,
      mode,
      modes,
      ipvUploadInProgress,
    };
  },
};
</script>

<style lang='scss'>
@import "../styles/webCamVerification.scss";
</style>
